<template>
  <div>
    <TogglePanel title="panel_properties" data-testid="toggle-panel">
      <DataListForm
        v-model="dataList"
        :selectable="false"
        :dataListParser="dataListParser"
      />
    </TogglePanel>
  </div>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";

import DataListForm from "@/components/control-sidebar/property-editors/data-list-form.vue";

export default {
  name: "ConnectorDataListForm",
  components: {
    TogglePanel,
    DataListForm
  },
  data() {
    return {};
  },
  computed: {
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panelOptions() {
      return this?.panel?.options || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || { screenId: "" })
        .screenId;
    },
    dataList: {
      set(values) {
        this.$set(this.panel.options, "dataList", values);
      },
      get() {
        return this?.panel?.options?.dataList || [];
      }
    }
  },
  watch: {
    panelOptions: {
      handler(n) {
        if (n) {
          this.$store.dispatch("dashboard/saveDraftPanel", {
            panel: this.panel,
            screenId: this.screenId
          });
        }
      },
      deep: true
    }
  },
  methods: {
    dataListParser(lst) {
      return (lst || []).filter(
        ({ id }) => `${id}`.includes("group") || /^\d+$/g.test(id)
      );
    }
  }
};
</script>

<style scoped>
.box-body > .content {
  min-height: inherit;
}

div.option {
  margin: 3px;
  white-space: nowrap;
}

div.option > i {
  margin-right: 3px;
}

.import-options {
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.import-options > span.btn {
  margin: 0 5px 0 0;
}

div.data-list {
  margin-top: -10px;
}

div.item {
  position: relative;
  width: 100%;
  font-size: 1em;
  border-top: 1px solid #e4e4e4;
  z-index: 1;
}

div.item-title {
  padding: 4px 22px 4px 2px;
  font-weight: 600;
  white-space: normal;
  width: 100%;
}

div.item-toolbar {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
}

div.item-toolbar > i {
  padding: 0 4px;
  min-width: 22px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>
