<template>
  <th
    class="noselect text-info"
    v-bind:class="cls + ' ' + (sortable ? 'clicable' : 'not-clicable')"
    v-bind:style="style"
    v-on:click.prevent.stop="onClick"
  >
    <span>
      <span v-if="name" :title="$t('titles.reorder_table')">
        <span v-html="$tc(title)"></span>
        <i
          v-if="field && field.tooltip"
          ref="tooltip"
          data-toggle="tooltip"
          data-placement="top"
          class="fa fa-question-circle"
          style="margin: 0 6px 0 2px"
        ></i>
        <i v-if="title && sortable" class="fa fa-sort"></i>
      </span>
      <span v-else>{{ $tc(title) }}</span>
    </span>
  </th>
</template>

<script>
export default {
  name: "tho",
  components: {},
  props: {
    field: {
      type: Object,
      required: false,
      default: () => null
    },
    name: {
      type: [String, Number],
      required: false,
      default: ""
    },
    title: {
      type: [String, Number],
      required: false,
      default: function () {
        return this.name || "untitled";
      }
    },
    cls: {
      type: String,
      required: false,
      default: ""
    },
    nowrap: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    sortable() {
      return (
        this?.field?.sortable === undefined || this?.field?.sortable || false
      );
    },
    style() {
      let props = {};
      props["white-space"] = this.nowrap ? "nowrap" : "auto";
      //
      let color = (this.field && this.field.color) || "";
      let backgroundColor = (this.field && this.field.backgroundColor) || "";
      if (backgroundColor) {
        props["background-color"] = backgroundColor || "transparent";
      }
      if (color) {
        props["color"] = color || "inherit";
      } else {
        if (backgroundColor) {
          props["color"] = "#000";
        }
      }
      return props;
    }
  },
  methods: {
    onClick() {
      this.$emit("sort", this.name);
    }
  },
  mounted() {
    if (this?.field?.tooltip && this.$refs.tooltip) {
      $(this.$refs.tooltip).tooltip({
        html: true,
        title: () => this.$t(this.field.tooltip)
      });
    }
  },
  beforeDestroy() {
    if (this?.$refs?.tooltip) {
      $(this.$refs.tooltip).tooltip("destroy");
    }
  }
};
</script>
<style scoped>
.not-clicable {
  cursor: default;
  opacity: 0.9;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
  background-color: #f7f7f7;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

i.fa.fa-sort {
  margin-left: 3px;
  opacity: 0.9;
  font-size: 90%;
}
</style>
