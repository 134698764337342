<template>
  <div class="data-selecion-panel">
    <div class="row" v-if="myOptions.searchEnabled">
      <div class="form-group col-md-12">
        <div class="input-group">
          <input
            type="text"
            v-model="query"
            class="form-control"
            v-bind:placeholder="$t('search')"
            ref="query"
            :title="$t('titles.insert_search_text')"
          />
          <div
            class="input-group-addon btn"
            v-on:click.prevent.stop="resetQuery"
          >
            <i v-if="query" class="fa fa-close"></i>
            <i v-else class="fa fa-search"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container" :style="panelStyle">
      <table
        class="table table-condensed table-bordered table-hover dataTable table-striped table-responsive"
        ref="table"
      >
        <thead>
          <tr>
            <th v-if="myOptions.multiSelection && canEdit" class="text-center">
              <input
                type="checkbox"
                v-model="all"
                :disabled="!hasSelectableItems"
                :title="$t('titles.select_all_data')"
              />
            </th>
            <tho @sort="setOrder" name="name" title="name" />
            <tho @sort="setOrder" name="value" title="value" cls="text-right" />
            <tho @sort="setOrder" name="unity_label" title="unit" />
            <tho @sort="setOrder" name="date_time" title="timestamp" />
            <th style="display: none">{{ $tc("alarm") }}</th>
            <th v-if="canEdit" class="text-center">{{ $t("configure") }}</th>
          </tr>
        </thead>
        <tbody v-if="equipmentDataList">
          <tr
            v-for="(item, index) in filteredList"
            v-bind:class="[
              isSelectable(item) ? 'selectable' : '',
              !item.enabled ? 'disabled' : ''
            ]"
            v-bind:key="index"
            v-bind:title="
              item.enabled
                ? $t('titles.select_data')
                : `${$tc('data')} ${$tc('disabled').toLowerCase()}`
            "
            v-on:click="onClick(item)"
          >
            <td
              v-if="myOptions.multiSelection && canEdit"
              class="text-center"
              style="padding: 0"
            >
              <input
                type="checkbox"
                v-bind:value="item.id"
                v-model="selectedData"
                :disabled="!isSelectable(item)"
              />
            </td>

            <!-- <td
              v-bind:title="
                'device (' +
                  item.device.id +
                  ') ' +
                  item.device.name +
                  ' - memory_type ' +
                  (item.memory_type.name || '')
              "
            > -->
            <td
              :title="`${$tc('data', 1)}: (${item.id}) ${item.name}\n${$tc(
                'device',
                1
              )}: (${item.device.id}) ${item.device.name}\n${$t(
                'memory_type'
              )}: ${item.memory_type.name || ''}\n${$t('value')}: ${
                item.current_value ? item.current_value.value : ''
              }`"
            >
              <div class="cell-name">
                <div class="cell-name-locker">
                  <i
                    class="fa"
                    v-bind:class="{
                      'fa-lock fa-disabled': item.read_only,
                      'fa-unlock ': !item.read_only
                    }"
                    :title="
                      item.read_only
                        ? $t('titles.data_not_allows_writing')
                        : !item.enabled
                        ? `${$tc('data')} ${$tc('disabled').toLowerCase()}`
                        : $t('titles.data_allows_writing')
                    "
                  ></i>
                </div>
                <div class="cell-name-text">
                  <div>{{ item.name.toUpperCase() }}</div>
                  <!-- <div class="small" v-if="item.name.toUpperCase()!=item.description.toUpperCase()">{{ item.description }}</div> -->
                  <div class="small" v-if="item.description">
                    {{ item.description }}
                  </div>
                </div>
              </div>
            </td>
            <!-- <td v-bind:title="item.device.name+' '+item.device.id">{{ item.device_name }}</td> -->
            <td
              class="text-right column-value"
              v-bind="
                item.pending_command || item.pending_mapping_write
                  ? {
                      title: $t('titles.pending_writing')
                    }
                  : {}
              "
              :title="`${$tc('device', 1)}: (${item.device.id}) ${
                item.device.name
              }\n${$t('memory_type')}: ${item.memory_type.name || ''}\n${$t(
                'value'
              )}: ${item.current_value ? item.current_value.value : ''}\n${
                item.read_only ? '' : $t('click_to_edit')
              } ${
                item.memory_size > 1
                  ? ' / ' + $t('ctrl_click_for_more_options')
                  : ''
              }
              `"
            >
              <!-- force component refresh for pending/updated data state -->
              <DataValueDisplay
                class="display"
                :entry="item"
                :zeroAsDash="zeroAsDash"
                :editor="true"
                @save="$emit('save', $event)"
                @editDataArray="$emit('editDataArray', $event)"
                :key="`${item.device.id}${
                  item.pending_command || item.pending_mapping_write ? 'p' : 'u'
                }`"
              />
            </td>
            <td>
              <span v-if="item.custom_format">
                <!-- {{ $t("customized") }} ({{ item.custom_format }}) -->
              </span>
              <span v-else>
                {{ item.unity_label }}
              </span>
            </td>
            <td class="cell-timestamp">
              {{ fmtDateTime(item) }}
            </td>
            <td style="display: none">
              <button
                class="btn btn-xs btn-default"
                v-bind:disabled="item.read_only"
              >
                <i class="fa fa-pencil"></i> {{ $t("config") }}
              </button>
            </td>
            <td v-if="canEdit" class="cell-config text-center">
              <button
                type="button"
                class="btn btn-xs btn-default"
                v-on:click="edit(item)"
                v-bind:title="$t('hints.access_to_configuration_form')"
              >
                <i class="fa fa-wrench large-text"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t("there_are_no_data_configured") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import tho from "@/components/tho.vue";
import DataValueDisplay from "@/components/data-value-display.vue";
export default {
  name: "EquipmentDataSelectionPanel",
  components: {
    tho,
    DataValueDisplay
  },
  props: {
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    screenId: {
      type: [String, Number],
      required: false,
      default: () => 0
    },
    selected: {
      type: Array,
      required: false,
      default: () => []
    },
    writableDataSelection: {
      type: Boolean,
      required: false,
      default: () => false
    },
    equipment: {
      type: Object,
      required: false,
      default: null
    },
    value: {
      type: Array
    },
    fields: {
      type: Array,
      required: false,
      default: () => []
    },
    widgetOptions: {
      type: Object,
      required: false,
      default: function() {
        return {};
      }
    },
    refreshTimerEnabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      all: false,
      selectedData: [],
      myOptions: {
        filter: "",
        multiSelection: true,
        searchEnabled: false,
        onItemClick: "select", // edit || select,
        refreshInterval: 30000,
        min_height: "350px",
        max_height: "auto",
        overflow_x: "hidden",
        overflow_y: "auto",
        zeroAsDash: false
      },
      sort_def: {
        column: "name",
        asc: true
      },
      query: "",
      overwritten: false
    };
  },
  computed: {
    dashboardMode() {
      return this.$store.getters["dashboard/mode"];
    },
    canEdit() {
      return this.$can("manage", "DadoCadastro");
    },
    hasSelectableItems() {
      return this.filteredList.some((i) => this.isSelectable(i));
    },
    refreshInterval() {
      return (
        ("refreshInterval" in this.myOptions &&
          this.myOptions.refreshInterval) ||
        30000
      );
    },
    zeroAsDash() {
      return "zeroAsDash" in this.myOptions ? this.myOptions.zeroAsDash : false;
    },
    connectorBasedFilter() {
      let lst = this.equipmentDataList || [];
      // if (this.overwritten) return lst;
      let custom_view = this?.equipment?.portal_data?.custom_view || null;
      if (custom_view && lst.length) {
        // begin: make it compatible with previous version (devicename+dataname)
        let setReferenceId = function(list) {
          for (var i in list || []) {
            let data = lst.find((item) => {
              return item.device.name + "." + item.name == list[i];
            });
            if (data) {
              list[i] = data.reference_id;
            }
          }
        };
        if (custom_view.order_type == "manual")
          setReferenceId(custom_view.order || []);
        setReferenceId(custom_view.excluded || []);
        setReferenceId(custom_view.checked || []);
        // end
        if (custom_view.order_type == "column") {
          this.setOrder(custom_view.order);
        } else if (custom_view.order_type == "manual") {
          lst = lst.sort(
            (a, b) =>
              custom_view.order.indexOf(this.dataPath(a)) -
              custom_view.order.indexOf(this.dataPath(b))
          );
        }
        if ((custom_view.excluded || []).length) {
          lst = lst.filter(
            (data) => !custom_view.excluded.includes(this.dataPath(data))
          );
        }
      }
      return lst;
    },
    filteredList() {
      let self = this;
      var query = self.query;
      let lst = this.connectorBasedFilter;
      let device_id = self.$store.getters.deviceId;
      if (device_id) {
        lst = lst.filter(function(i) {
          return i.device.id == device_id;
        });
      }
      // if (!this?.panel?.options?.dataList?.length) {
      if (self.myOptions.filter == "historic") {
        lst = lst.filter(function(i) {
          return i.historico;
        });
      }
      // lst = this.connectorBasedFilter(lst);
      // }
      return lst.filter(function(item) {
        return self.$utils.queryStrAtr(query, item);
      });
    },
    dataList() {
      return (
        this.$store.getters["dashboard/dataList"] ||
        (this?.equipment?.id
          ? this.$store.getters["dashboard/dataListFrom"](this.equipment.id) ||
          []
          : [])
      );
    },
    equipmentDataList: function() {
      var self = this;
      var lst = [];

      // filter from panel
      if (this?.panel?.options?.dataList?.length) {
        return this.dataList
          .filter((data) => {
            let ix = this.panel.options.dataList.findIndex(
              ({ data_id }) => data_id == data.id
            );
            if (ix >= 0) {
              let option = this.panel.options.dataList[ix];
              data._ix = ix;
              if (option.data_value_index) {
                data.portal_data = data.portal_data || {};
                data.portal_data.data_value_index = option.data_value_index;
              }
              return true;
            }
            return false;
          })
          .sort((a, b) => a._ix - b._ix);
      }

      // filter from connector
      lst = self.dataList.filter(({ clp_id }) =>
        this?.equipment?.id ? this.equipment.id == clp_id : true
      );
      //============================================================
      // sort it
      // properties cant be sorted if they are not at the parent
      return self.sort(
        lst.map(function(i) {
          i.device_name = i.device.name;
          if (i.current_value) {
            i.date_time = i.current_value.date_time;
            i.value = i.current_value.value;
          } else {
            i.date_time = 0;
            i.value = 0;
          }
          return i;
        })
      );
    },
    fieldsAsObject: function() {
      var self = this;
      var lst = (self.fields || []).map(function(i) {
        return [i.data_id, i];
      });
      if (lst & lst.length) {
        if ("fromEntries" in Object) {
          return Object.fromEntries(lst);
        } else {
          var lineCfg = {};
          for (var i in lst) {
            lineCfg[lst[i][0]] = lst[i][1];
          }
          return lineCfg;
        }
      }
      return null;
    },
    panelStyle() {
      let style = {};
      if (parseInt(this.screenId) >= 1000000000) return style;
      // if ((this?.panel?.style || {})["overflow-x"] == "hidden") {
      //   style["overflow-x"] = "hidden";
      // }
      if ((this?.panel?.style || {})["overflow-y"] == "hidden") {
        style["overflow-y"] = "hidden";
      }
      return style;
    }
  },
  watch: {
    all: function(nData, oData) {
      var self = this;
      if (nData) {
        self.selectedData = self.equipmentDataList
          .filter((i) => self.isSelectable(i))
          .map((i) => i.id);
      } else {
        self.selectedData = [];
      }
    },
    selectedData: function(nData, oData) {
      var self = this;
      if (!this.canEdit) return;
      self.$emit("input", nData);
      if (self.myOptions.multiSelection) return;
      if (self.myOptions.onItemClick == "edit") {
        var lst = self.equipmentDataList.filter(function(i) {
          return !i.read_only && i.id == nData;
        });
        if (lst.length) {
          self.$emit("edit");
        }
      }
    },
    filteredList(list) {
      if (
        list.length &&
        this.$refs.table.clientWidth > this.$el.clientWidth &&
        this.$refs.table.clientHeight <=
        parseInt(this.panel.style["min-height"] ?? 0)
      ) {
        this.$emit("widthError", true);
      } else {
        this.$emit("widthError", false);
      }
    }
  },
  methods: {
    edit(data) {
      if (!data || this.dashboardMode === "editor" || !this.canEdit) return;
      let device = (data && data.device) || null;
      let connector = (device && device.connector) || null;
      if (connector) {
        this.$router.push(
          `/dashboard/edit/connector/${connector.id}/device/${device.id}/data/${data.id}`
        );
      }
    },
    dataPath(data) {
      // return (
      //   data.reference_id ||
      //   ((data.device && data.device.name) || "") + "." + data.name
      // );
      let prefix = data?.device?.data_collector_device_id
        ? `${data.device.reference_id}.`
        : "";
      return `${prefix}${data.reference_id}`;
    },
    isSelectable(item) {
      if (this.writableDataSelection) {
        return (
          !item.read_only &&
          item.enabled &&
          (this.$root.$formatter.memorySize(item) == 1 ||
            this.$root.$formatter.dataValueCurrentIndex(item) >= 0)
        );
      }
      return true;
    },
    resetQuery() {
      let self = this;
      self.query = "";
      self.$nextTick(() => self.$refs.query.focus());
    },
    sort(lst) {
      let self = this;
      const _v = (atrr, value) => {
        switch (attr) {
          case "value":
            return parseFloat(value);
          case "date_time":
            return new Date(value).getTime();
          default:
            return value;
        }
      };
      let asc = self.sort_def.asc;
      let attr = self.sort_def.column || "name";
      let ret = lst.sort(function(a, b) {
        if (attr in a && attr in b) {
          if (
            asc
              ? _v(attr, a[attr]) > _v(attr, b[attr])
              : _v(attr, b[attr]) > _v(attr, a[attr])
          )
            return 1;
          if (
            asc
              ? _v(attr, b[attr]) > _v(attr, a[attr])
              : _v(attr, a[attr]) > _v(attr, b[attr])
          )
            return -1;
        }
        return 0;
      });
      return ret;
    },
    setOrder(sort) {
      if (sort.column) {
        this.sort_def = sort;
        // } else if (!this?.equipment?.portal_data?.custom_view?.order) {
      } else {
        this.sort_def.asc =
          sort == this.sort_def.column ? !this.sort_def.asc : true;
        this.sort_def.column = sort;
      }
      // this.overwritten = true;
    },
    onClick: function(item) {
      var self = this;
      if (!self.isSelectable(item)) {
        return;
      }
      if (!self.myOptions.multiSelection) {
        this.selectedData = [];
      }
      if (
        this.selectedData &&
        this.selectedData.length &&
        this.selectedData.indexOf(item.id) >= 0
      ) {
        this.selectedData.splice(this.selectedData.indexOf(item.id), 1);
      } else {
        this.selectedData.push(item.id);
      }
    },
    fmtDateTime: function(item) {
      return (
        (item &&
          item?.current_value &&
          this.$dt.format(item?.current_value?.date_time)) ||
        "-"
      );
    },
    fmtName: function(item) {
      var self = this;
      let fields = self.fieldsAsObject;
      let field = fields && item.id in fields && fields[item.id];
      let fmt = (field && fields[item.id].label) || item.name;
      return fmt.toUpperCase();
    }
  },
  beforeCreate() {
    var self = this;
    self.timer = null;
  },
  destroyed: function() {
    var self = this;
    if (self.timer) {
      clearInterval(self.timer);
      self.timer = null;
    }
  },
  mounted: function() {
    var self = this;
    var lst = [];
    var selected = self.selected;
    if (selected.length) {
      var equipmentDataList = self.equipmentDataList || [];
      lst = equipmentDataList
        .filter(function(item) {
          return selected.indexOf(item.id) >= 0;
        })
        .map(function(item) {
          return item.id;
        });
    }
    if (lst && lst.length) {
      self.selectedData = lst;
    }
    var widgetOptions =
      (self.widgetOptions &&
        "options" in self.widgetOptions &&
        self.widgetOptions.options) ||
      null;
    if (widgetOptions) {
      // merge them both
      self.myOptions = { ...self.myOptions, ...widgetOptions };
    }
    // if (this.refreshTimerEnabled) {
    //   // when attached to a sidebar menu (not on equipment dashboard - it might need to update datalist itself)
    //   self.$nextTick(function() {
    //     self.timer = setInterval(function() {
    //       let lst = self.dataList;
    //       if (lst.length) {
    //         self.$emit(
    //           "refreshList",
    //           lst.map((i) => {
    //             return i.id;
    //           })
    //         );
    //       }
    //     }, self.refreshInterval);
    //   });
    // }
  }
};
</script>

<style scoped>
.table-container {
  overflow: auto;
  flex: 1;
}

.table-container > table {
  margin-bottom: 0;
}

.data-selecion-panel {
  display: flex;
  flex-direction: column;
}

.selectable:hover {
  cursor: pointer;
  color: #10143e;
}
.fa-disabled {
  color: rgb(150 150 150);
}
.large-text {
  font-size: 2rem;
  padding-top: 2px;
}
.disabled *:not(:last-child) {
  color: #777;
  opacity: 0.8;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.table > tbody > tr > td > input[type="checkbox"] {
  vertical-align: middle;
}

.column-value {
  min-width: 150px;
  max-width: 300px;
  padding: 5px;
  vertical-align: middle;
}

.column-value > .display {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-name {
  line-height: 1em;
  white-space: nowrap;
  font-size: 90%;
  vertical-align: middle;
}
.cell-name-locker {
  display: inline-block;
  vertical-align: middle;
  min-width: 24px;
}

.cell-name-text {
  display: inline-block;
  vertical-align: middle;
  /* font-size: 90%; */
}

.cell-timestamp {
  white-space: nowrap;
  font-size: 95%;
  vertical-align: middle;
}
.cell-config {
}
.input-group .form-control {
  z-index: 0 !important;
}
</style>
